.screenBody {
	margin: 0 auto;
	width: 70%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.screenTitle {
	height: 8%;
	font-size: 2rem;
}

.paymentBold {
	color: #00c1e7;
}

.text-content {
	text-align: center;
	margin-bottom: 5%;
}

.payment-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 60%;
	height: auto;
	padding-bottom: 5%;
	background-color: white;
	border-radius: 20px;
	box-shadow: -10px 5px 5px #d8dfe0;
	margin-bottom: 10%;
}
.payment-form > label {
	display: inline-block;
	text-align: center;
	width: 80%;
	margin-top: 5%;
}

.form-group {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.form-group > label {
	align-self: baseline;
	margin-left: 5%;
	margin-bottom: 3%;
	margin-top: 2%;
}

.twoLinesGroup {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	margin-bottom: 5%;
}

.twoLinesGroup > div {
	display: none;
}
.twoLinesGroup > input {
	width: 45%;
	height: 35px;
	border: 1px solid #bec5ce;
	border-radius: 4px;
}

.group {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	width: 100%;
	margin-top: 2%;
	margin-bottom: 2%;
	align-items: center;
}

.group > label {
	margin-left: 15%;
}

.group > input {
	width: 70%;
	height: 100%;
	border: 1px solid #bec5ce;
	border-radius: 4px;
}

.icon-borded {
	color: gray;
}

.payment-input {
	width: 80%;
	height: 45px;
	border: 1px solid #bec5ce;
	border-radius: 4px;
	background-color: white;
	font-size: 0.9rem;
}

.selectable {
	padding: 1%;
	margin-bottom: 5%;
}

.oneLine {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	margin-bottom: 5%;
}

.form-group > p {
	width: 70%;
	font-size: 0.9rem;
	text-align: center;
}

.w-middle {
	width: 45%;
}

.w-middle > div {
	width: 100%;
	overflow: hidden;
}

.form-control {
	width: 100% !important;
}

.button-submit {
	background: #0e3ec4;
	border-radius: 8px;
	border: 1px solid #0e3ec4;
	font-size: 1rem;
	height: 40px;
	width: 30%;
	font-weight: bold;
	color: white;
}

.group-inline {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: center;
}

.group-inline {
	height: auto;
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-bottom: 5%;
}

.phoneNumber > input {
	height: 35px;
}

.email-icon {
	position: relative;
	left: 5%;
}

.group-inline > input {
	height: 35px;
}

@media screen and (max-width: 940px) {
	.screenBody {
		width: 100%;
	}
	.payment-form {
		width: 70%;
	}

	.payment-form {
		padding-left: 5%;
		padding-right: 5%;
	}
}

@media screen and (max-width: 580px) {
	.payment-form {
		width: 80%;
		height: 70%;
	}
	.twoLinesGroup {
		flex-direction: column;
		margin: 0;
	}

	.twoLinesGroup > input {
		margin-bottom: 4%;
		width: 80%;
		height: 25px;
	}

	.oneLine {
		flex-direction: column;
	}
	.oneLine > input {
		height: 25px;
	}
	.group-inline > input {
		height: 25px;
		width: 55%;
	}

	.w-middle {
		width: 85%;
	}
	.button-submit {
		width: 50%;
	}
	.screenTitle {
		font-size: 1.2rem;
	}

	.text-content {
		font-size: 0.8rem;
	}
	.form-group > p {
		font-size: 0.8rem;
	}

	.payment-form > label {
		font-size: 0.8rem;
	}
}
