@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

.modal {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(88, 114, 145, 0.6);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #FFFFFF;
  width: 365px;
  padding: 32px 16px;
  box-shadow: -8px 8px 26px rgba(0, 5, 12, 0.15);
  border-radius: 16px;
}

@media screen and (min-width: 768px) {
  .modal-content {
    width: 472px;
    padding: 2rem 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .modal-content {
    width: 630px;
    padding: 32px 40px;
  }
}

button {
  margin-top: 10px;
}

.title-text {
  text-align: center;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 120%;
  /* or 29px */
  color: #101A27;
}

.blue-text {
  margin-left: 6px;
  color: #00C1E7;
}

.text-color {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #505B69;
}

.text-color-bold {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #505B69;
}
 
.inputRows {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 1rem;
}

.inputStyles {
  padding: 16px;
  gap: 8px;
  width: 100%;
  height: 36px;
  background: #FFFFFF;
  border: 1px solid #CAD2DB;
  border-radius: 8px;
}

.btnBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnStyles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  width: 139px;
  height: 44px;
  background: #0E3EC4;
  border-radius: 8px;
  color: #FFFFFF;
  border: none;
}
